import { Button, Typography } from '@app/components';
import { EScanAnalyticUnit, PlanLimitOutDto } from '@app/swagger-types';
import { Collapse } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlanLimitOutDtoKey } from '../..';
import { NEW_SUBSCRIPTION_PLAN_LIMIT_KEYS, SUBSCRIPTION_PLAN_LIMIT_KEYS } from '../../user.const';
import { IncludeItem } from './IncludeItem';

/**
 * keys for special handling (e.g. with dependent fields)
 */
type SpecialPlanLimitKey =
  | 'scanAnalyticsQuantity'
  | 'scanAnalyticsUnit'
  | 'fileExtensions'
  | 'hasFileExtensions'
  | 'hasRealTimeDataDashboard'
  | 'qrTypes'
  | 'ccpaCompliant'
  | 'gdprCompliant';
type SimplePlanLimitKey = Exclude<PlanLimitOutDtoKey, SpecialPlanLimitKey>;

export const SubscriptionPlanIncludes: React.FC<{
  isOldPlanUser: boolean;
  planLimit: PlanLimitOutDto;
  isShowAllFeatures: boolean;
  setIsShowAllFeatures: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOldPlanUser, planLimit, isShowAllFeatures, setIsShowAllFeatures }) => {
  const { t } = useTranslation();

  const memoRenderFeature = useMemo(() => {
    console.log('planLimit', planLimit);
    const variants: Record<SimplePlanLimitKey, string> = {
      ['dedicatedCustomerSuccessManager']: t('subscription.feature.dedicatedCustomerSuccessManager'),
      ['emailSupport']: t('subscription.feature.emailSupport'),
      ['knowledgeBase']: t('subscription.feature.knowledgeBase'),
      ['hasBulkQrCodeGeneration']: t('subscription.feature.bulkQRCodeGeneration'),
      ['hasQrDesignCustomisations']: t('subscription.feature.qrDesignCustomisations'),
      ['hasTrackScanGPS']: t('subscription.feature.tracksScansGPSCoordinates'),
      ['hasTrackScanDevice']: t('subscription.feature.tracksScansDeviceType'),
      ['hasTrackScanLocation']: t('subscription.feature.tracksScansLocations'),
      ['hasTrackScanTimeOfDay']: t('subscription.feature.tracksScansTimeOfDay'),
      ['hasLabels']: t('subscription.feature.labels'),
      ['hasTemplates']: t('subscription.feature.templates'),
      qrAdjustable: t('subscription.feature.qrAdjustable'),
    };

    // TODO remove any
    const anyRecord: Record<string, string> = planLimit as any;

    const checkLimitKey = (
      key: PlanLimitOutDtoKey
    ):
      | false
      | {
          isAvailable: boolean;
          title: React.ReactNode;
          value: React.ReactNode;
        } => {
      if (key === 'ccpaCompliant' || key === 'gdprCompliant') {
        return false;
      }
      if (key === 'hasQrDesignCustomisations') {
        return false;
      }
      if (key === 'scanAnalyticsQuantity') {
        return false;
      }
      if (key === 'scanAnalyticsUnit') {
        return false;
      }
      if (key === 'fileExtensions') {
        return false;
      }
      if (key === 'hasRealTimeDataDashboard') {
        const getValue = () => {
          if (!planLimit.scanAnalyticsUnit) {
            return t('subscription.feature.full');
          }
          if (planLimit.scanAnalyticsQuantity === 1 && planLimit.scanAnalyticsUnit === EScanAnalyticUnit.YEAR) {
            return t('subscription.feature.oneYear');
          }
          if (planLimit.scanAnalyticsQuantity === 6 && planLimit.scanAnalyticsUnit === EScanAnalyticUnit.MONTH) {
            return t('subscription.feature.sixMonths');
          }
          if (planLimit.scanAnalyticsQuantity === 7 && planLimit.scanAnalyticsUnit === EScanAnalyticUnit.DAY) {
            return t('subscription.feature.sevenDays');
          }
          return `${planLimit.scanAnalyticsQuantity} ${planLimit.scanAnalyticsUnit}`;
        };
        return {
          isAvailable: true,
          title: t('subscription.feature.scanAnalytics'),
          value: getValue(),
        };
      }
      if (key === 'qrTypes') {
        return {
          isAvailable: true,
          title: t('subscription.feature.qrTypes'),
          value: t('subscription.feature.all'),
        };
      }
      if (key === 'hasFileExtensions') {
        return {
          isAvailable: true,
          title: t('subscription.feature.fileExtensions'),
          value: planLimit.fileExtensions?.join(', '),
        };
      }
      const isAvailable = Boolean(anyRecord[key]);
      const title = variants[key];
      // TODO i18n
      const value = isAvailable ? t('subscription.feature.yes') : t('subscription.feature.no');
      return {
        isAvailable,
        title,
        value,
      };
    };
    return (key: PlanLimitOutDtoKey) => {
      const feature = checkLimitKey(key);
      if (!feature) {
        return null;
      }
      return <IncludeItem key={key} isAvailable={feature.isAvailable} title={feature.title} value={feature.value} />;
    };
  }, [t, planLimit]);

  if (isOldPlanUser) {
    return (
      <>
        <div className="mx-5 space-y-3">
          <Typography variant="m" className="!mb-5 font-semibold text-gray-800">
            {t('subscription.includes')}:
          </Typography>
          <IncludeItem isAvailable title={t('subscription.feature.maxScans')} value={planLimit.amountMaxScans || '∞'} />

          {SUBSCRIPTION_PLAN_LIMIT_KEYS.slice(0, 2).map(memoRenderFeature)}
          <Collapse in={isShowAllFeatures}>
            <div className="space-y-3">{SUBSCRIPTION_PLAN_LIMIT_KEYS.slice(2).map(memoRenderFeature)}</div>
          </Collapse>
        </div>
        <Button
          className="mx-[2px] mb-[2px] rounded-[11px] rounded-t-none border-gray-100 bg-gray-100"
          color="secondary"
          size="large"
          variant="outlined"
          onClick={() => {
            setIsShowAllFeatures((prev) => !prev);
          }}
        >
          {!isShowAllFeatures ? t('subscription.seeAllFeatures') : t('subscription.seeLessFeatures')}
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="mx-5 space-y-3 mb-10">
        <Typography variant="m" className="!mb-5 font-semibold text-gray-800">
          {t('subscription.includes')}:
        </Typography>
        {NEW_SUBSCRIPTION_PLAN_LIMIT_KEYS.map(memoRenderFeature)}
      </div>
    </>
  );
};
