import { GetUsersParams } from '@app/domain/user';
import { pageableData } from '@app/utils/yup.utils';
import { apiService, validateAndAssert } from '@app/services/api-service';
import { UserOutSchema } from '@app/auth/api/auth.schema';
import { CustomerAdminSyncStripeOutDto, CustomerInDto, UserStatusInDto } from '@app/swagger-types';
import { AuthUserSchema } from '@app/auth/api/auth.dto';
import { CustomerAdminOutSchema } from './user.schema';
import { object, string } from 'yup';
import { NullablePartial } from '@app/utils/type.utils';

export abstract class UserApi {
  // POST /api/v1/private/customers/generate-auth-token/{customerId}
  static async generateAuthToken(customerId: string) {
    return validateAndAssert(
      apiService.post(`private/customers/generate-auth-token/${customerId}`),
      object({
        accessToken: string().required(),
      })
    );
  }

  static async getCustomers(params: GetUsersParams) {
    return validateAndAssert(
      apiService.get('/private/customers', {
        params,
      }),
      pageableData(CustomerAdminOutSchema)
    );
  }

  static async getAdmins(params: GetUsersParams) {
    return validateAndAssert(
      apiService.get('/private/admins', {
        params,
      }),
      pageableData(UserOutSchema)
    );
  }

  static async patchStatus(userId: string, body: UserStatusInDto) {
    return apiService.patch<void>(`/private/users/${userId}/status`, body);
  }

  static async putCustomer(body: AuthUserSchema) {
    return apiService.put<void>(`/private/auth/me`, body);
  }

  static async patchCustomer(body: NullablePartial<AuthUserSchema>) {
    return apiService.patch<void>(`/private/auth/me`, body);
  }

  static async adminPatchCustomer(customerId: string, body: Partial<CustomerInDto>) {
    return validateAndAssert(apiService.patch(`/private/customers/${customerId}`, body), CustomerAdminOutSchema);
  }

  static async adminForceStripeSync(customerId: string) {
    const { data } = await apiService.post<CustomerAdminSyncStripeOutDto>(
      `/private/customers/${customerId}/force-stripe-sync`
    );
    return data;
  }
}
