import { PermissionCrown } from '@app/domain/plan/components/permission-crown/PermissionCrown';
import { UIStyledSection } from '../../components/UIStyledSection';
import { useTranslation } from 'react-i18next';

export const UISectionPermissionCrown: React.FC = () => {
  const { t } = useTranslation();

  return (
    <UIStyledSection title="Permission crown">
      <div className="flex gap-4 items-center">
        <PermissionCrown requireUpgrade /> {t('limit.upgradeToUnlockThisFeature')}
      </div>
      <div className="flex gap-4 items-center">
        <PermissionCrown requireUpgrade={false} /> feature already available
      </div>
    </UIStyledSection>
  );
};
