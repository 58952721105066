import { CheckCircleIcon } from '@app/assets';
import { EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';
import { Trans, useTranslation } from 'react-i18next';
import QRCodeSampleSrc from './assets/qr-code-sample.png';
import DeviceMacbookSrc from './assets/device-macbook.png';
import { ReactComponent as DownloadQR1SVG } from './assets/download-qr1.svg';
import { ReactComponent as ScansContainerSVG } from './assets/scans-container.svg';
import { ReactComponent as FramesGroupSVG } from './assets/frames-group.svg';
import { ReactComponent as FramesIphoneSVG } from './assets/frames-iphone.svg';
import { ReactComponent as FramesCustomizeSVG } from './assets/frames-customize.svg';
import { ReactComponent as ShapesGroupSVG } from './assets/shapes-group.svg';
import { ReactComponent as ShapesIphoneSVG } from './assets/shapes-iphone.svg';
import { ReactComponent as ShapesCustomizeSVG } from './assets/shapes-customize.svg';
import { ReactComponent as ScansTotalSVG } from './assets/scans-total.svg';
import { ReactComponent as ScansChartSVG } from './assets/scans-chart.svg';
import { IS_WORDPRESS_WIDGET, typedEnv } from '@app/environment/typed-env';

export const useWidgetGetDialogContent = (
  source: EGTAGRegistrationPopUp | null
): {
  header: JSX.Element;
  description: JSX.Element;
  decorativeContent: JSX.Element;
  popup: EGTAGRegistrationPopUp | null;
} => {
  const { t } = useTranslation();

  let header = <>{t('widget.downloadDialog.title')}</>;
  let description = <></>;
  let decorativeContent = <></>;
  const popup = source;
  switch (source) {
    case EGTAGRegistrationPopUp.FRAME:
      header = (
        <Trans i18nKey="widget.signUpDialog.frames.header">
          Attract more scans with <span className="text-accept-main">frames</span>
        </Trans>
      );
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.frames.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.frames.benefit1')}
            </div>
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.frames.benefit2')}
            </div>
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.frames.benefit3')}
            </div>
          </div>
        </div>
      );
      decorativeContent = (
        <>
          <FramesCustomizeSVG className="top-10 absolute left-20 drop-shadow-xl" />
          <FramesGroupSVG className="bottom-40 absolute left-10 drop-shadow-xl" />
          <FramesIphoneSVG className="bottom-32 absolute right-4 w-[142px] drop-shadow-xl" />
        </>
      );
      break;
    case EGTAGRegistrationPopUp.PATTERN:
    case EGTAGRegistrationPopUp.COLOR:
      header = (
        <Trans i18nKey={'widget.signUpDialog.shapes.header'}>
          Stay fresh with different <span className="text-accept-main">shapes & colors</span>
        </Trans>
      );
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.shapes.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.shapes.benefit1')}
            </div>
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.shapes.benefit2')}
            </div>
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.shapes.benefit3')}
            </div>
          </div>
        </div>
      );
      decorativeContent = (
        <>
          <ShapesCustomizeSVG className="top-10 absolute left-20 drop-shadow-xl" />
          <ShapesGroupSVG className="bottom-40 absolute left-10 drop-shadow-xl" />
          <ShapesIphoneSVG className="bottom-32 absolute right-4 w-[142px] drop-shadow-xl" />
        </>
      );
      break;
    case EGTAGRegistrationPopUp.SCAN_TRACKING:
      header = <>{t('widget.signUpDialog.scans.header')}</>;
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.scans.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.scans.benefit1')}
            </div>
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.scans.benefit2')}
            </div>
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.scans.benefit3')}
            </div>
          </div>
        </div>
      );
      decorativeContent = (
        <>
          <img
            src={maybeRemoteSrc(DeviceMacbookSrc)}
            alt="macbook"
            className="bottom-40 absolute right-0 w-4/5 max-w-xs"
          />
          <div className="top-8 absolute left-1/2 -translate-x-1/2 w-20 h-20 p-2 bg-[#F1F3F7] rounded drop-shadow-xl">
            <img src={QRCodeSampleSrc} alt="qr-code" className="w-full h-full" />
          </div>
          <ScansTotalSVG className="bottom-72 absolute left-10 drop-shadow-xl" />
          <ScansChartSVG className="bottom-40 absolute left-10 drop-shadow-xl" />
        </>
      );
      break;
    case EGTAGRegistrationPopUp.CREATE_BULK:
      header = <>{t('widget.signUpDialog.bulk.header')}</>;
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.bulk.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.bulk.benefit1')}
            </div>
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.bulk.benefit2')}
            </div>
            <div className="flex gap-2 items-center">
              <CheckCircleIcon />
              {t('widget.signUpDialog.bulk.benefit3')}
            </div>
          </div>
        </div>
      );
      decorativeContent = (
        <>
          <img
            src={maybeRemoteSrc(DeviceMacbookSrc)}
            alt="macbook"
            className="bottom-40 absolute right-0 w-4/5 max-w-xs"
          />
          <div className="top-8 absolute left-1/2 -translate-x-1/2 w-20 h-20 p-2 bg-[#F1F3F7] rounded drop-shadow-xl">
            <img src={QRCodeSampleSrc} alt="qr-code" className="w-full h-full" />
          </div>
          <ScansTotalSVG className="bottom-72 absolute left-10 drop-shadow-xl" />
          <ScansChartSVG className="bottom-40 absolute left-10 drop-shadow-xl" />
        </>
      );
      break;
    default:
      description = (
        <div className="flex flex-col gap-4">
          <div className="flex gap-2 items-center">
            <CheckCircleIcon />
            {t('widget.downloadDialog.benefit1')}
          </div>
          <div className="flex gap-2 items-center">
            <CheckCircleIcon />
            {t('widget.downloadDialog.benefit2')}
          </div>
          <div className="flex gap-2 items-center">
            <CheckCircleIcon />
            {t('widget.downloadDialog.benefit3')}
          </div>
          <div className="flex gap-2 items-center">
            <CheckCircleIcon />
            {t('widget.downloadDialog.benefit4')}
          </div>
          <div className="flex gap-2 items-center">
            <CheckCircleIcon />
            {t('widget.downloadDialog.benefit5')}
          </div>
        </div>
      );
      decorativeContent = (
        <>
          <img
            src={maybeRemoteSrc(DeviceMacbookSrc)}
            alt="macbook"
            className="bottom-40 absolute right-0 w-4/5 max-w-xs"
          />
          <div className="top-8 absolute left-1/2 -translate-x-1/2 w-20 h-20 p-2 bg-[#F1F3F7] rounded drop-shadow-xl">
            <img src={QRCodeSampleSrc} alt="qr-code" className="w-full h-full" />
          </div>
          <DownloadQR1SVG className="bottom-64 absolute left-10 drop-shadow-xl" />
          <ScansContainerSVG className="bottom-32 absolute left-10 drop-shadow-xl" />
        </>
      );
  }
  return { header, description, popup, decorativeContent };
};

/**
 * TODO make universal util
 * @note large media seems to be kept as files
 * while small media inlined into base64 so we should not prefix them with app host url
 */
const maybeRemoteSrc = (src: string) => {
  if (IS_WORDPRESS_WIDGET && src.startsWith('/')) {
    return `${typedEnv.REACT_APP_HOST_URL}${src}`;
  }
  return src;
};
