import { IS_PROD_ENV, typedEnv } from '@app/environment/typed-env';
import { DesignOutDto, QRCodeOutDto } from '@app/swagger-types';
import { WithNullableKeys } from '@app/utils/type.utils';
import { DesignFormSchema } from '../template/api/template.form.schema.api';
import { QRCodeFormSchema } from './api/qr-code.form.schema.api';
import { QRRendererProps } from './components/QRCodeRendererSVG';

export const getDeviceId = () => {
  return localStorage.getItem('deviceId');
};

export const setDeviceId = (id: string) => {
  localStorage.setItem('deviceId', id);
};

export const getLinkForCode = (id: string) => {
  return `${typedEnv.REACT_APP_HOST_URL}/qr/${id}`;
};

export const getLinkForQrCodeTemplate = (id: string) => {
  return `${typedEnv.REACT_APP_HOST_URL}/templates/${id}/edit`;
};

const ENV_SUFFIX = IS_PROD_ENV ? '' : typedEnv.REACT_APP_ENV_NAME;
export const getQRfileNameForDownload = (qrId?: string, fileName = '') => {
  if (qrId && !fileName.includes(qrId)) {
    if (!fileName) {
      fileName = 'qr-code.png';
    }
    const delimiter = /\s/.test(fileName) ? ' ' : '-';
    const maybeDelimiter = IS_PROD_ENV ? '' : delimiter;
    const name = fileName.replace(/\.png$/, '');
    const ext = 'png';
    fileName = `${name}${delimiter}${qrId}${maybeDelimiter}${ENV_SUFFIX}.${ext}`;
  }
  if (!fileName) {
    const delimiter = IS_PROD_ENV ? '' : '-';
    fileName = `qr-code${delimiter}${ENV_SUFFIX}.png`;
  }
  return fileName;
};

export const extractStylePropsFromQRForm = (form: QRCodeFormSchema, template?: DesignOutDto) => {
  const { patternColor, cornerColor, patternType, cornerType, libraryId, templateId } = form;
  const qrProps =
    templateId && template
      ? {
          backgroundColor: template.backgroundColor,
        }
      : {
          backgroundColor: form.backgroundColor,
        };
  const frameProps: Partial<DesignOutDto> =
    templateId && template
      ? {
          frameType: template.frameType,
          frameText: template.frameText,
          frameTextSize: template.frameTextSize,
          frameTextColor: template.frameTextColor,
          frameBackgroundColor: template.frameBackgroundColor,
        }
      : {
          frameType: form.frameType,
          frameText: form.frameText || undefined,
          frameTextSize: form.frameTextSize,
          frameTextColor: form.frameTextColor,
          frameBackgroundColor: form.frameBackgroundColor,
        };
  return { ...qrProps, patternColor, cornerColor, patternType, cornerType, libraryId, ...frameProps };
};

export const extractStylePropsFromQRTemplateForm = (form: DesignFormSchema): Partial<DesignFormSchema> => {
  const {
    backgroundColor,
    patternColor,
    cornerColor,
    patternType,
    cornerType,
    libraryId,
    frameType,
    frameText,
    frameTextSize,
    frameTextColor,
    frameBackgroundColor,
  } = form;
  return {
    backgroundColor,
    patternColor,
    cornerColor,
    patternType,
    cornerType,
    libraryId,
    frameType,
    frameText,
    frameTextSize,
    frameTextColor,
    frameBackgroundColor,
  };
};

export const extractStylePropsFromQRDesign = (
  design?: WithNullableKeys<DesignOutDto, 'createdBy' | 'template' | 'library'> | null
) => {
  if (!design) {
    return {};
  }
  const {
    backgroundColor,
    patternColor,
    cornerColor,
    patternType,
    cornerType,
    frameType,
    frameText,
    frameTextSize,
    frameTextColor,
    frameBackgroundColor,
  } = design;
  const libraryId = design.library?.id;
  return {
    backgroundColor,
    patternColor,
    cornerColor,
    patternType,
    cornerType,
    libraryId,
    frameType,
    frameText,
    frameTextSize,
    frameTextColor,
    frameBackgroundColor,
  };
};

// TODO match QRCodeOutDto with schemas to avoid Omit and WithNullableKeys
export const extractRenderPropsFromQR = (
  qr?:
    | (Omit<QRCodeOutDto, 'website' | 'design' | 'createdWithFlow'> & {
        design?: WithNullableKeys<Omit<DesignOutDto, 'template'>, 'library'> | null;
      })
    | null
): Partial<QRRendererProps> => {
  const design = qr?.design;
  const { publicLogoType } = qr || {};
  if (!design) {
    return {
      publicLogoType,
    };
  }
  const {
    backgroundColor,
    patternColor,
    cornerColor,
    patternType,
    cornerType,
    frameType,
    frameText,
    frameTextSize,
    frameTextColor,
    frameBackgroundColor,
  } = design;
  const libraryId = design.library?.id;
  return {
    // from design
    backgroundColor,
    patternColor,
    cornerColor,
    patternType,
    cornerType,
    libraryId,
    frameType,
    frameText,
    frameTextSize,
    frameTextColor,
    frameBackgroundColor,
    // from root
    publicLogoType,
  };
};
