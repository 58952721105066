// types without API for less dependencies for widget

export type QRDownloadHandlerFn = (config?: {
  /**
   * generated link not clicked automatically when true
   * @default false
   */
  skipAutoDownload?: boolean;
  /**
   * create artificial delay so browser has time
   * to render UI animations before blocking UI with
   * HTML conversion
   * TODO remove delay after html2render changed to custom algorithms
   */
  delay?: number;
  /**
   * require to download QR only via SSR
   */
  onlyServerSideRendering?: boolean;
  /**
   * optional parameter to provide to validate currently
   * rendered id matches expected qr id.
   * Should throw an error on mismatch.
   */
  expectedQrId?: string;
  fileType?: EQRDownloadType;
}) => Promise<{
  canvasDataURL: string;
}>;

export enum EQRDownloadType {
  PNG = 'PNG',
  SVG = 'SVG',
}
