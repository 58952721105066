export const translationForDomainKeys = {
  header: {
    amountOfCodes: '<0>{{amount}} of {{max}}</0> QR codes',
  },
  billing: {
    plan: 'plan',
    paymentMethod: 'Payment method',
    updatePaymentMethod: 'Update Payment Method',
    cardExpires: 'Expires',
    // Invoice,Date,Plan,Status,Amount,Billing and invoices,No invoices yet,Refetch
    invoiceTable: {
      invoice: 'Invoice',
      date: 'Date',
      plan: 'Plan',
      status: 'Status',
      amount: 'Amount',
      billingAndInvoices: 'Billing and invoices',
      placeholderNoInvoices: 'No invoices yet',
    },
    invoice: {
      /* #region  /**=========== status =========== */
      paid: 'Paid',
      failed: 'Failed',
      pending: 'Pending',
      disputed: 'Disputed',
      /* #endregion  /**======== status =========== */
    },
  },
  limit: {
    upgradeToUnlockThisFeature: 'Upgrade to unlock this feature.',
    usageResetsInDays_one: 'Usage resets in {{count}} day',
    usageResetsInDays_other: 'Usage resets in {{count}} days',
  },
  subscription: {
    chooseTheMostSuitablePackage: 'Choose the most suitable package',
    selectAPlan: 'Select a plan',
    trialPlanEndsIn: 'Trial plan ends in:',
    /* #region  /**=========== status =========== */
    active: 'Active',
    expired: 'Expired',
    canceled: 'Canceled',
    expiresOn: 'Expires on',
    renews: 'Renews',
    cancels: 'Cancels',
    /* #endregion  /**======== status =========== */
    billMonthly: 'Bill monthly',
    billQuarterly: 'Bill quarterly',
    billYearly: 'Bill yearly',
    selectCurrency: 'Select currency',
    currency: 'Currency',
    dynamicQRCodes: 'Dynamic QR Codes',
    qrCodes: 'QR Codes',
    unlimited: 'Unlimited',
    // @deprecated remove "scans" after release
    scans: 'scans',
    monthlyScans: 'monthly scans',
    qrScans: 'QR scans',
    scansUnlimited: 'Ulimited scans',
    ofAmount: 'of',
    perMonth: 'per month',
    // @deprecated remove "perQuarter" after release
    perQuarter: 'per quarter',
    perMonthQuarterly: 'per month, billed quarterly',
    perMonthYearly: 'per month, billed annually',
    upgradeTo: 'Upgrade to',
    downgradeTo: 'Downgrade to',
    subscribedToSuccessfuly: 'Subscribed to {{planName}} successfuly',
    changeToMonthly: 'Change to monthly',
    changeToQuarterly: 'Change to quarterly',
    changeToYearly: 'Change to yearly',
    monthly: 'monthly',
    quarterly: 'quarterly',
    yearly: 'yearly',
    notAvailable: 'Not available',
    currentPlan: 'Current Plan',
    buttonUpgradePlan: 'Upgrade Plan',
    buttonManagePlan: 'Manage Plan',
    cancelPlan: 'Cancel Plan',
    cancelUpdate: 'Cancel Update',
    renewPlan: 'Renew Plan',
    areYouSureToCancelPlan: 'Are you sure you want to cancel {{planName}} plan?',
    areYouSureToCancelScheduledUpdate: 'Are you sure to cancel scheduled update to {{planName}} plan?',
    youCanRenewBeforeTheExpirationDate: 'You can renew it before the expiration date.',
    planUpdateScheduled: 'Update scheduled to plan {{planName}} ({{planCycle}}) on {{scheduledDate}}',
    includes: 'Includes',
    seeAllFeatures: 'See All Features',
    seeLessFeatures: 'See Less Features',
    downgradePlan: {
      confirmationDialogTitle: 'Just a double check...',
      confirmationDialogText:
        'Downgrading may cause your QR codes to no longer be active when the new (lower) package limit is exceeded.',
      buttonConfirm: 'Yes, I understand',
      buttonCancel: 'Keep my current plan',
    },
    feature: {
      yes: 'Yes',
      no: 'No',
      all: 'All',
      full: 'Full',
      sevenDays: '7 days',
      sixMonths: '6 months',
      oneYear: '1 year',
      maxScans: 'scans',
      templates: 'Templates',
      labels: 'Labels',
      qrAdjustable: 'Adjustable',
      scanAnalytics: 'Scan analytics',
      realTimeDataDashboard: 'Scan analytics (full)',
      tracksScansTimeOfDay: 'Tracks scans/time of day',
      tracksScansLocations: 'Tracks scans/locations',
      tracksScansDeviceType: 'Tracks scans/device type',
      tracksScansGPSCoordinates: 'GPS tracking ',
      qrDesignCustomisations: 'Design (full)',
      bulkQRCodeGeneration: 'Bulk creation',
      gdprCompliant: 'GDPR compliant',
      ccpaCompliant: 'CCPA compliant',
      knowledgeBase: 'Knowledge base',
      emailSupport: 'Email support',
      dedicatedCustomerSuccessManager: 'Dedicated customer success manager',
      fileExtensions: 'File extensions',
      qrTypes: 'QR types',
    },
    faq: {
      question1: 'What if something 1?',
      answer1: 'Yes, you can do it. For more details please contact our support 1.',
      question2: 'What if something 2?',
      answer2: 'Yes, you can do it. For more details please contact our support 2.',
      question3: 'What if something 3?',
      answer3: 'Yes, you can do it. For more details please contact our support 3.',
    },
    planError: {
      MAX_QR_CODE_USED_RESTRICTION: 'You have more QR codes in your account than the limit of this plan',
      PLAN_TEMPLATE_RESTRICTION: 'You used templates that are included in the STARTER package',
      PLAN_GPS_RESTRICTION: 'You used GPS tracking that is included in the STARTER package',
      PLAN_SCAN_RESTRICTION: 'You have more scans of QR codes than the limit of this plan',
    },
  },
  qr: {
    entityName: 'QR code',
    createQRCode: 'Create QR Code',
    editQRCode: 'Edit QR Code',
    preview: 'Preview',
    buttonNext: 'Next',
    buttonBack: 'Back',
    buttonConfirm: 'Confirm',
    buttonAndSave: 'And Save',
    buttonSaveChanges: 'Save changes',
    buttonDownload: 'Download',
    scans: 'Scans',
    socialNetworks: 'Social networks',
    selectNetwork: 'Select a network and paste a link',
    expired: {
      qrCodeNoLongerValid: 'QR code is no longer valid',
      /* #region  /**=========== TODO remove after release =========== */
      activateYourQr: 'Activate your qr now or share this link with the owner.',
      reactivateThisQRCode:
        'Reactivate this QR code if you are the owner or share this link with the owner of this QR code.',
      /* #endregion  /**======== TODO remove after release =========== */
      thanksForScanning: 'Thanks for scanning',
      notification: 'Notification',
      qrCodeNoLongerWorking: 'This QR code you tried to scan is no longer working for some reason.',
      yourScannerWorksWell: 'Your QR code scanner works well.',
      whatNow: 'What now?',
      haveYouScannedCode: 'Have you scanned the QR code?',
      informTheOwner: 'Inform the owner. So that person knows that the QR code no longer works.',
      areYouOwner: 'Are you the QR code owner?',
      clickOnIAmOwner: `Click on "I'm the owner" and log in to your account to activate the QR.`,
      buttonIAmOwner: `I'm the Owner`,
      ownerNotified: 'Owner notified',
      thankYou: 'Thank you!',
      //
      buttonClaimAndActivate: 'Claim and activate QR',
    },
    stepType: {
      stepTitle: 'Type',
      selectYourQRCodeType: 'Select your QR Code type',
      websiteURL: 'Website URL',
      websiteDescription: 'Website link to QR code',
      multiLinks: 'Multi links',
      multiLinksDescription: 'Link to multiple web pages',
      pdf: 'PDF',
      pdfDescription: 'Link to any PDF file',
      vCard: 'vCard Plus',
      vCardDescription: 'Your digital business card',
    },
    stepInput: {
      stepTitle: 'Input',
      generalInfoTab: 'General info',
      designTab: 'Design',
      imageOptional: 'Image (optional)',
      addImageDescription: 'Add an image using the + button. Format: PNG, max file size 5MB.',
      noImage: 'No image',
    },
    stepCustomize: {
      stepTitle: 'Customize',
      logo: {
        tabTitle: 'Logo',
        description: 'Use an existing logo or add a new one using the + button. Format: PNG, max file size 5MB.',
        noLogo: 'No logo',
        deleteLogo: 'Delete logo?',
        yesDelete: 'Yes, delete',
      },
      frame: {
        tabTitle: 'Frame',
        noFrame: 'No frame',
      },
      pattern: {
        tabTitle: 'Pattern',
      },
      template: {
        tabTitle: 'Template',
        saveDesign: 'Save Design',
      },
    },
    stepDone: {
      stepTitle: 'Done',
      almostReady: 'Almost ready!',
      enterNameForYourQRCode: 'Enter a name for your QR code',
    },
    design: {
      frameCustomText: 'Custom text',
      frameTextSize: 'Size',
      frameTextColor: 'Text color',
      frameBackgroundColor: 'Frame background color',
      patternColor: 'QR pattern color',
      backgroundColor: 'QR background color',
      cornerColor: 'Corners color',
    },
    websiteInput: {
      title: 'Website URL',
      enterURL: 'Enter URL',
      yourCodeGeneratedAutomatically: 'Your QR Code will be generated automatically',
    },
    multilinkInput: {
      headerLinksTab: 'Header / Links / Social',
      header: 'Header',
      title: 'Title',
      description: 'Description (optional)',
      descriptionPlaceholder: 'Enter description',
      buttonAddLink: 'Add Link',
      link: 'link',
      linksSectionTitle: 'Links',
      linkTitleLabel: 'Link title',
      linkTitlePlaceholder: 'Enter title',
      linkURLLabel: 'Enter URL',
    },
    pdfInput: {
      content: 'Content',
      dragPdfOr: 'Drag PDF here or',
      browse: 'Browse',
      hint: 'When someone scans the QR code, they are immediately forwarded to the uploaded PDF',
    },
    vCardInput: {
      personalInformation: 'Personal information',
    },
    bulk: {
      createBulkButton: 'Create Bulk',
      downloadExampleTitle: 'Download the CSV example',
      downloadExampleSubtitle: 'Download the CSV file and add your links to it.',
      uploadCSVTitle: 'Upload the CSV example',
      uploadCSVSubtitle: 'Complete the sample CSV and re-upload it.',
      dragCSVOr: 'Drag CSV here or',
      browse: 'Browse',
    },
  },
  template: {
    createTemplate: 'Create template',
    editTemplate: 'Edit template',
    title: 'Title',
    enterName: 'Enter a name for your template',
  },
} as const;
