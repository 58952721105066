import {
  ECurrency,
  EPlanCycle,
  EPlanStatus,
  EPlanType,
  EScanAnalyticUnit,
  PlanDetailsOutDto,
  PlanLimitOutDto,
} from '@app/swagger-types';
import { checkTypeCompliance } from '@app/utils/type.utils';
import { oneOfEnum, oneOfEnumOptionalNullable } from '@app/utils/yup.utils';
import { array, boolean, InferType, number, object, string } from 'yup';

export const PlanLimitOutDtoSchema = object({
  planType: oneOfEnum(EPlanType),
  endOfCurrentSubscription: string().nullable(),
  amountMaxScans: number().nullable(),
  amountMaxQrCodes: number().nullable(),
  amountOfMaxBulkQrCode: number().nullable(),
  hasFileExtensions: boolean().required(),
  hasTemplates: boolean().required(),
  hasLabels: boolean().required(),
  hasRealTimeDataDashboard: boolean().required(),
  hasTrackScanTimeOfDay: boolean().required(),
  hasTrackScanLocation: boolean().required(),
  hasTrackScanDevice: boolean().required(),
  hasTrackScanGPS: boolean().required(),
  hasQrDesignCustomisations: boolean().required(),
  hasBulkQrCodeGeneration: boolean().required(),
  gdprCompliant: boolean().required(),
  ccpaCompliant: boolean().required(),
  knowledgeBase: boolean().required(),
  emailSupport: boolean().required(),
  dedicatedCustomerSuccessManager: boolean().required(),
  qrAdjustable: boolean().nullable(),
  scanAnalyticsQuantity: number().nullable(),
  scanAnalyticsUnit: oneOfEnumOptionalNullable(EScanAnalyticUnit),
  fileExtensions: array(string().required()).default(() => []),
});
export type PlanLimitOutDtoSchema = InferType<typeof PlanLimitOutDtoSchema>;
checkTypeCompliance<PlanLimitOutDto, PlanLimitOutDtoSchema>();

export const PlanPriceOutDtoSchema = object({
  id: string().required(),
  price: number().required(),
  createdDate: string().required(),
  planCycle: oneOfEnum(EPlanCycle),
  planCurrency: oneOfEnum(ECurrency),
});
export type PlanPriceOutDtoSchema = InferType<typeof PlanPriceOutDtoSchema>;

export const PlanOutDtoSchema = object({
  id: string().required(),
  name: string().required(),
  prices: array().of(PlanPriceOutDtoSchema),
  planLimit: PlanLimitOutDtoSchema,
  createdDate: string().required(),
});
export type PlanOutDtoSchema = InferType<typeof PlanOutDtoSchema>;

// TODO refactor as PlanLimitOutDtoSchema.concat(object({...}))
export const PlanDetailsOutSchema = object({
  // id: string().required(),
  // name: string().required(),
  // prices: array().of(PlanPriceOutDtoSchema),
  // planLimit: PlanLimitOutDtoSchema,
  // createdDate: string().required(),
  planStatus: oneOfEnum(EPlanStatus),
  planType: oneOfEnum(EPlanType),
  amountMaxQrCodes: number().nullable(),
  amountMaxScans: number().nullable(),
  hasTemplates: boolean(),
  endOfCurrentSubscription: string(),
  hasLabels: boolean(),
  hasRealTimeDataDashboard: boolean(),
  hasTrackScanTimeOfDay: boolean(),
  hasTrackScanLocation: boolean(),
  hasTrackScanDevice: boolean(),
  hasTrackScanGPS: boolean(),
  hasQrDesignCustomisations: boolean(),
  hasBulkQrCodeGeneration: boolean(),
  amountOfMaxBulkQrCode: number().nullable(),
  gdprCompliant: boolean(),
  ccpaCompliant: boolean(),
  knowledgeBase: boolean(),
  emailSupport: boolean(),
  dedicatedCustomerSuccessManager: boolean(),
  endOfTrialCurrentSubscription: string(),
  hasDisputedPayment: boolean().nullable(),
  hasFileExtensions: boolean(),
  fileExtensions: array(string().defined()).default([]),
});
export type PlanDetailsOutSchema = InferType<typeof PlanDetailsOutSchema>;
checkTypeCompliance<PlanDetailsOutSchema, PlanDetailsOutDto>();
