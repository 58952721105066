import { Button, Tooltip, Typography } from '@app/components';
import { useHandler } from '@app/hooks/useHandler.hook';
import { clsxm } from '@app/styles/clsxm';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EQRDownloadType, QRDownloadHandlerFn } from '../qr-code-internal.types';
import { QRCodeRendererSVG, QRRendererProps } from '../QRCodeRendererSVG';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { PermissionCrown } from '@app/domain/plan/components/permission-crown/PermissionCrown';

export const DownloadQRCodeModal: React.FC<
  NiceModalHocProps & { rendererProps: QRRendererProps; onClose?: () => void }
> = NiceModal.create(({ rendererProps, onClose }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { canDownloadSVG } = usePlanLimits();

  const [downloadHandler, setDownloadHandler] = useState<QRDownloadHandlerFn | undefined>(undefined);

  const onDownloadHandler = useHandler((downloadFn?: QRDownloadHandlerFn) => {
    // setter has to be a function to avoid downloadFn call
    setDownloadHandler(() => downloadFn);
  });

  const closeDialog = () => {
    modal.remove();
    onClose?.();
  };

  const [loadingType, setLoadingType] = useState<EQRDownloadType | null>(null);

  useEffect(() => {
    if (!loadingType || !downloadHandler) {
      return;
    }
    let mounted = true;
    downloadHandler({ delay: 100, fileType: loadingType }).finally(() => {
      if (!mounted) {
        return;
      }
      setLoadingType(null);
    });
    return () => {
      mounted = false;
    };
  }, [downloadHandler, loadingType]);

  const isLoading = Boolean(loadingType);

  const buttonSVG = (
    <span className="flex-1">
      <Button
        loading={loadingType === EQRDownloadType.SVG}
        variant="outlined"
        className="flex items-center gap-4"
        type="submit"
        fullWidth
        disabled={!downloadHandler || !canDownloadSVG || isLoading}
        onClick={() => {
          setLoadingType(EQRDownloadType.SVG);
        }}
      >
        SVG <PermissionCrown requireUpgrade={!canDownloadSVG} />
      </Button>
    </span>
  );
  return (
    <Modal open={modal.visible} onClose={closeDialog} onClick={(e) => e.stopPropagation()}>
      <div
        className={clsxm(
          'absolute top-[50%] left-[50%] w-full max-w-[calc(100%-24px)] -translate-x-[50%] -translate-y-[50%] overflow-hidden rounded-3xl bg-white p-8',
          'sm:max-w-auto sm:w-auto sm:min-w-[450px] sm:p-12'
        )}
      >
        <div className="flex flex-col gap-6">
          <Typography variant="xl" className="font-bold">
            {t('dialog.download')}
          </Typography>
          <div className="mx-auto w-[320px] max-w-full">
            <QRCodeRendererSVG {...rendererProps} onDownloadHandler={onDownloadHandler} />
          </div>
          <div className="flex justify-between gap-4">
            {/* <Button onClick={closeDialog} disabled={isLoading} className="flex-1" variant="outlined">
              {t('dialog.cancel')}
            </Button> */}
            {canDownloadSVG ? buttonSVG : <Tooltip title={t('limit.upgradeToUnlockThisFeature')}>{buttonSVG}</Tooltip>}
            <span className="flex-1">
              <Button
                loading={loadingType === EQRDownloadType.PNG}
                variant="outlined"
                type="submit"
                fullWidth
                disabled={!downloadHandler || isLoading}
                onClick={() => {
                  setLoadingType(EQRDownloadType.PNG);
                }}
              >
                PNG
              </Button>
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
});
