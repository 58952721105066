import { FiRrArrowSmallRight, FiRrCrossSmall } from '@app/assets';
import { Dialog } from '@app/hoc';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { QRWidgetProductPreview } from '@app/qr-widget/dialog/components/QRWidgetProductPreview';
import { clsxm } from '@app/styles/clsxm';
import { ECustomerSegment } from '@app/swagger-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerSegmentTile } from './CustomerSegmentTile';
import { Button } from '@app/components';
import { useUpdateCustomer } from '@app/domain/user/api/user.hooks.api';

export const OnboardingDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const isMobile = useIsMobile();
  const [step, setStep] = useState(EStep.INITIAL);

  const renderStep = () => {
    const onSetStep = (newStep: EStep) => setStep(newStep);
    if (step === EStep.GET_DISCOUNT_TODAY) {
      // TODO
      return null;
    }
    return <StepInitial onSetStep={onSetStep} />;
  };

  const isCloseAvailable = step !== EStep.INITIAL;
  const isRightSideAvailable = step !== EStep.INITIAL;

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      hideHeader
      paperClassName="text-accept-dark-blue shadow-none bg-transparent rounded-none"
      maxWidth="md"
      fullScreen={isMobile}
    >
      <div className="flex flex-col gap-4 pt-4 md:pt-0">
        <div className="rounded-2xl bg-white shadow overflow-hidden">
          {isCloseAvailable && (
            <div className={clsxm('flex w-full justify-end pr-4 pt-4', !isMobile && 'absolute top-0 right-0 z-10')}>
              <div
                className="cursor-pointer hover:bg-white rounded"
                onClick={() => {
                  onClose();
                }}
                aria-hidden
              >
                <FiRrCrossSmall />
              </div>
            </div>
          )}
          <div className={clsxm('grid', isRightSideAvailable && 'md:grid-cols-2')}>
            {/* left side */}
            <div className="bg-white p-8 md:min-h-[520px]">{renderStep()}</div>
            {/* right side */}
            {!isMobile && isRightSideAvailable && <QRWidgetProductPreview triggerPopup={null} />}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

enum EStep {
  INITIAL,
  GET_DISCOUNT_TODAY,
  SELECT_CYCLE,
}

interface CommonProps {
  onSetStep: (newStep: EStep) => void;
}

const StepInitial: React.FC<CommonProps> = (
  {
    // TODO handle next steps
    // onSetStep
  }
) => {
  const { t } = useTranslation();
  const [selectedSegment, setSelectedSegment] = useState<null | ECustomerSegment>(null);
  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();

  return (
    <div className="flex flex-col gap-4 h-full justify-between">
      <div className="flex flex-col gap-4 md:px-4">
        <div className="text-xl font-bold">{'What will you be using Trueqrcode for?'}</div>
        {`We'll use this to recommend the best QR types for you.`}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {ORDERED_SEGMENT_TILES.map((e) => (
          <CustomerSegmentTile
            segment={e}
            key={e}
            isSelected={selectedSegment === e}
            onSelect={() => {
              if (isLoading) {
                return;
              }
              setSelectedSegment(e);
            }}
          />
        ))}
      </div>
      <div className="self-end">
        <Button
          variant="contained"
          className="w-full"
          onClick={() => {
            if (!selectedSegment) {
              return;
            }
            updateCustomer(
              {
                customerSegment: selectedSegment,
              },
              {
                onSuccess: () => {
                  // TODO handle next steps
                  // onSetStep(EStep.GET_DISCOUNT_TODAY);
                },
              }
            );
          }}
          disabled={!selectedSegment}
          loading={isLoading}
        >
          {t('dialog.next')}
          <FiRrArrowSmallRight />
        </Button>
      </div>
    </div>
  );
};

const ORDERED_SEGMENT_TILES = [
  ECustomerSegment.STUDENT,
  ECustomerSegment.NON_PROFIT_OR_CHARITY,
  ECustomerSegment.TEACHER,
  ECustomerSegment.LARGE_COMPANY,
  ECustomerSegment.PERSONAL,
  ECustomerSegment.SMALL_BUSINESS,
];
