export const translationForCommonKeys = {
  auth: {
    layout: {
      title: 'Join thousands unlocking the power of QR codes with Trueqrcode.',
      benefit1Bold: 'Free forever',
      benefit1Rest: 'for core features',
      benefit2: 'QR remains yours',
      benefit3: 'See who scans your QR',
      trustedAt: 'Trusted at companies large and small',
    },
    otp: {
      titleAlmostSignedUp: `You are almost signed up`,
      enterTheCodeSentTo: `Enter the code we have sent to`,
      toFinishSignUp: `to finish signing up`,
      emailAlreadyRegistered: 'This email address is already known to us',
      codeLabel: 'Code',
      didNotGetCode: `Didn't get the code?`,
      resendInSeconds: `Resend in {{seconds}} seconds`,
      buttonResendCode: 'Resend code',
      errorInvalidCode: 'Invalid code',
      errorTooManyAttempts: 'Too many attempts, try again later',
    },
    error: {
      emailNotRegistered: 'email not registered',
    },
    // @deprecated check for use
    welcomeBack: 'Welcome back!',
    continueWithGoogle: 'Continue with Google',
    or: 'Or',
    email: 'Email',
    password: 'Password',
    enterYourEmail: 'Enter your email',
    enterYourPassword: 'Enter your password',
    forgotPassword: 'Forgot password?',
    signIn: 'Sign In',
    dontHaveAnAccount: 'Do not have an account?',
    signUp: 'Sign Up',
    forgot: {
      description: 'Enter your email. We will email intructions on how to reset your password.',
      submit: 'Receive new password',
      backTo: 'Back to',
      sent: {
        linkHasBeenSent: 'Link has been sent',
        description: 'A link to reset password has been sent to your email.',
        resendEmailLink: 'Resend email link',
      },
    },
    resetPassword: {
      changePassword: 'Change Password',
      newPassword: 'New Password',
      createNewPassword: 'Create a new password',
      confirmPassword: 'Confirm password',
      enterYourNewPassword: 'Enter your new password',
      saveChanges: 'Save changes',
    },
    signUpStep: {
      // @deprecated check for use
      getStartedForFree: 'Get started for free',
      description: 'The largest QR platform in the world.',
      iAgreeWithTerms: 'I agree with the <2>Terms</2> and <6>Privacy Policy</6>',
      proceed: 'Proceed',
      alreadyHaveAnAccount: 'Already have an account?',
    },
    logOut: 'Log out',
    confirmLogOut: {
      logOut: 'Log out?',
      yesLogOut: 'Yes, log out',
      cancel: 'Cancel',
    },
  },
  dialog: {
    areYouSure: 'Are you sure?',
    areYouSureToDeleteThis: 'Are you sure you want to delete this',
    yesDelete: 'Yes, Delete',
    deleteQuestionTitle: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel',
    delete: 'Delete',
    changeName: 'Change name',
    name: 'Name',
    update: 'Update',
    save: 'Save',
    saveChanges: 'Save changes',
    download: 'Download',
    continue: 'Continue',
    close: 'Close',
    next: 'Next',
  },
  support: {
    pageTitle: 'Help',
    doYouNeedHelp: 'Do you need help?',
    customerSupport: 'Customer support',
    haveAQuestion: 'Have a question?',
    /**
     * @deprecated Remove after full migration to freemium
     **/
    contactSupport: 'Contact support',
    helpcenter: 'Helpcenter',
  },
  trial: {
    yourTrialPeriodIsOver: 'Your trial period is over',
    yourQRCodesWillExpireIn: 'Your QR Codes will expire in',
    yourQRCodesExpired: 'Your QR Codes expired',
    morePossibilities: 'More possibilities?',
    upgradeNow: 'Upgrade Now',
  },
  search: {
    searchByTitle: 'Search by title',
    type: 'Type',
    all: 'All',
    notSelected: 'Not selected',
    sortBy: 'Sort by',
    filters: 'Filters',
    mostRecent: 'Most recent',
    nameAZ: 'Name (A-Z)',
    mostScanned: 'Most scanned',
    lastCreated: 'Last created',
    clearAll: 'Clear all',
    done: 'Done',
    showingAmountOfTotalCodes: 'Showing {{amount}} of {{total}} codes',
    showMore: 'Show more',
  },
  manageResults: {
    refetch: 'Refetch',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    selected: 'Selected',
    delete: 'Delete',
    deleteSelectedTemplates_one: 'Delete selected template?',
    deleteSelectedTemplates_other: 'Delete selected templates?',
    yesDelete: 'Yes, delete',
  },
  toaster: {
    templateSaved: 'Template successfully saved',
    templatesDeleted: 'Templates deleted',
    templateDeleted: 'Template deleted',
    qrCreated: 'QR code successfully created',
    qrUpdated: 'QR code successfully updated',
    qrsDeleted: 'QR codes deleted',
    qrDeleted: 'QR code deleted',
    bulkQrsCreated: 'Bulk QR codes successfully created',
    avatarUpdated: 'Avatar updated successfully',
    avatarDeleted: 'Avatar deleted successfully',
    changesSaved: 'Changes saved',
  },
  error: {
    fileNotSupported: 'File {{fileName}} is not supported',
    noServerConnection: 'No connection with the server.',
    unknownServerError: 'Unknown server error',
    notFound: 'Not Found',
    onlyPdfFile: 'Only PDF file allowed',
    maxFileSize: 'Max file size 15 MB',
    pdfFileRequired: 'PDF file required',
    invalidEmail: 'Invalid email',
  },
  placeholder: {
    youHaveNoData: 'You have no data',
    noQRsCreated: `You haven't created QR codes yet`,
    noTemplatesCreated: `You haven't created Templates yet`,
    noScans: 'No scans yet',
  },
  globalWarning: {
    warning: 'Warning!',
    scheduledPlanCancel: {
      yourPlanIsAboutToBeCancelled: 'Your {{planName}} plan is about to be cancelled.',
      currentPlanActiveUntil: 'Current plan will be active until:',
      // TODO maybe avoid "dongraded date" because we don't reset at midnight and we don't know user timezone
      yourLimitOfScansWillBeDowngraded: 'Your limit of scans will be downgraded:',
    },
    canceledPlan: {
      yourPlanWasCanceled: 'Your {{planName}} plan was cancelled.',
      currentPlan: 'Current plan:',
      yourLimitOfScans: 'Your limit of scans:',
      scans: 'scans',
    },
    disputedPayment: {
      youHaveDisputedPayment: 'You have a disputed payment.',
      payForYourSubscription:
        'Pay the disputed payment for your subscription so that the QR codes start working again.',
      view: 'View',
      myInvoices: 'My Invoices',
      forDetailedInfo: 'for detailed info.',
    },
    scanLimit80: {
      youAreOver: 'You are over',
      percent80: '80%',
      ofYourScans: 'of your scans limit.',
      pleaseUpgradeYourPlanNow: `Please upgrade your plan now, before your QR codes don't work anymore when you are over the limit.`,
    },
    scanLimit100: {
      youHaveReached: 'You have reached',
      percent100: '100%',
      ofYourScans: 'of your scans limit.',
      yourCodesAreNotWorkingAnymore: `Your QR codes are not working anymore. Make sure to upgrade now.`,
    },
  },
} as const;
