import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RootLayout } from '@app/modules/RootLayout';
import { QueryParamProvider, ExtendedStringifyOptions, transformSearchStringJsonSafe } from 'use-query-params';
import { DialogRenderComponent } from '@app/services/dialog-manager';

import { ThemeWrapper, ResponsiveManager } from '@app/hoc';

import 'react-toastify/dist/ReactToastify.css';
import { RouteQueryParamsAdapter } from './RouteAdapter';
import NiceModal from '@ebay/nice-modal-react';
import { AppRouter } from '@app/router/AppRouter';
import { AppSideEffects } from './AppSideEffects';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

function App() {
  return (
    <AppRouter>
      <QueryParamProvider
        ReactRouterRoute={RouteQueryParamsAdapter as unknown as React.FunctionComponent}
        stringifyOptions={queryStringifyOptions}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeWrapper>
            <ResponsiveManager>
              <NiceModal.Provider>
                <DialogRenderComponent />
                <AppSideEffects />
                <RootLayout />
              </NiceModal.Provider>
            </ResponsiveManager>
          </ThemeWrapper>
        </QueryClientProvider>
      </QueryParamProvider>
      <ToastContainer />
    </AppRouter>
  );
}

export default App;
