import { ECustomerSegment } from '@app/swagger-types';
import { BackpackSVG, CharitySVG, LargeCompanySVG, PersonalSVG, SmallBusinessSVG, TeacherSVG } from './icons';
import { useTranslation } from 'react-i18next';
import { clsxm } from '@app/styles/clsxm';

export const CustomerSegmentTile: React.FC<{
  segment: ECustomerSegment;
  isSelected?: boolean;
  onSelect?: () => void;
}> = ({ segment, isSelected, onSelect }) => {
  const { t } = useTranslation();

  const getContent = (): {
    Icon: React.FC;
    title: string;
    description: string;
  } => {
    const defaultAsStudet = {
      Icon: BackpackSVG,
      title: t('onboarding.segmentStudent.title'),
      description: t('onboarding.segmentStudent.description'),
    };
    if (segment === ECustomerSegment.NON_PROFIT_OR_CHARITY) {
      return {
        Icon: CharitySVG,
        title: t('onboarding.segmentCharity.title'),
        description: t('onboarding.segmentCharity.description'),
      };
    }
    if (segment === ECustomerSegment.TEACHER) {
      return {
        Icon: TeacherSVG,
        title: t('onboarding.segmentTeacher.title'),
        description: t('onboarding.segmentTeacher.description'),
      };
    }
    if (segment === ECustomerSegment.LARGE_COMPANY) {
      return {
        Icon: LargeCompanySVG,
        title: t('onboarding.segmentLargeCompany.title'),
        description: t('onboarding.segmentLargeCompany.description'),
      };
    }
    if (segment === ECustomerSegment.PERSONAL) {
      return {
        Icon: PersonalSVG,
        title: t('onboarding.segmentPersonal.title'),
        description: t('onboarding.segmentPersonal.description'),
      };
    }
    if (segment === ECustomerSegment.SMALL_BUSINESS) {
      return {
        Icon: SmallBusinessSVG,
        title: t('onboarding.segmentSmallBusiness.title'),
        description: t('onboarding.segmentSmallBusiness.description'),
      };
    }
    return defaultAsStudet;
  };
  const { Icon, title, description } = getContent();
  return (
    <button
      className={clsxm(
        'flex gap-2 flex-col p-4 rounded-lg outline-gray-200 outline-1 outline',
        'text-left',
        isSelected && 'bg-accept-hover/10 outline-accept-hover'
      )}
      onClick={() => {
        onSelect?.();
      }}
    >
      <div className="flex md:flex-col gap-4 flex-wrap">
        <Icon />
        <div>{title}</div>
      </div>
      <div className="text-xs">{description}</div>
    </button>
  );
};
