import { IS_NOT_PROD_ENV } from './environment/typed-env';
import localforage from 'localforage';

if (IS_NOT_PROD_ENV) {
  localforage.config({
    driver: localforage.LOCALSTORAGE,
  });
}

import { usePersistAutoLink } from '@app/domain/qr-code/hooks/usePersistAutoLink';

/**
 * These side effects happen before navigation resolved.
 * Important case - autoLinkQRID for already authorized users:
 * - sign up and sign in pages are not available for already authorized users
 * - so users redirected before RootModule rendered
 * - auto redirect removes query params so auto link info lost
 * - but this component triggers auto link before auto redirect
 */
export const AppSideEffects: React.FC = () => {
  usePersistAutoLink();
  return null;
};
