import { UIStyledSection } from '../components/UIStyledSection';
import { EFrameType } from '@app/swagger-types';
import { FrameContainer } from '@app/domain/qr-code/components/frame/FrameContainer';
import { QRCodeRendererSVG, QRRendererProps } from '@app/domain/qr-code/components/QRCodeRendererSVG';
import { useState } from 'react';
import styled from '@emotion/styled';

const Textarea = styled.textarea`
  min-height: 400px;
  width: 100%;
`;

export const UITabQRCodes: React.FC = () => {
  const [rawProps, setRawProps] = useState(() => JSON.stringify(DEFAULT_QR_PROPS, null, 2));
  const [props, setProps] = useState(DEFAULT_QR_PROPS);

  return (
    <div className="flex flex-col gap-4">
      <UIStyledSection title="QR Codes">
        <div>
          <Textarea
            value={rawProps}
            onChange={(e) => {
              setRawProps(e.target.value);
              try {
                const json = JSON.parse(e.target.value);
                setProps(json);
              } catch (error) {
                //
              }
            }}
          ></Textarea>
        </div>
        <div className="grid grid-cols-5 gap-4 max-w-4xl">
          {ORDERED_FRAME_TYPES.map((e) => (
            <FrameContainer {...props} key={e} type={e} />
          ))}
        </div>
        <div className="flex flex-wrap gap-4 max-w-4xl items-center">
          {ORDERED_FRAME_TYPES.map((e) => (
            <QRCodeRendererSVG {...props} key={e} frameType={e} />
          ))}
        </div>
        <div className="grid grid-cols-5 gap-4 items-center max-w-4xl">
          {ORDERED_FRAME_TYPES.map((e) => (
            <QRCodeRendererSVG {...props} key={e} frameType={e} />
          ))}
        </div>
      </UIStyledSection>
    </div>
  );
};

const DEFAULT_QR_PROPS: QRRendererProps = {
  value: 'hello',
  patternColor: 'silver',
  frameText: 'SCAN ME',
  frameTextColor: 'black',
  frameBackgroundColor: '#4499ff',
  backgroundColor: '#444559',
};

const ORDERED_FRAME_TYPES = [
  EFrameType.NONE,
  EFrameType.BORDER_LABEL_BOTTOM,
  EFrameType.BORDER_LABEL_TOP,
  EFrameType.ARROW_LABEL_TOP,
  EFrameType.ARROW_LABEL_BOTTOM,
  EFrameType.BORDER_SPACE_LABEL_BOTTOM,
  EFrameType.BORDER_SPACE_LABEL_TOP,
  EFrameType.BORDER_INTERRUPTION,
  EFrameType.NO_BORDER,
  EFrameType.BORDER,
];
