import { AuthUserSchema } from '@app/auth/api/auth.dto';
import { useStore } from '@app/store/useStore.hook';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { UserApi } from '../user.api';
import { NullablePartial } from '@app/utils/type.utils';

export const useUpdateCustomer = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, NullablePartial<AuthUserSchema>> = {}) => {
  const queryClient = useQueryClient();
  const { getMe } = useStore(({ getMe }) => ({
    getMe,
  }));

  return useMutation(
    async (Dto) => {
      return await UserApi.patchCustomer(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await getMe();

        queryClient.invalidateQueries();
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};
