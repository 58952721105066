import { clsxm } from '@app/styles/clsxm';
import { useMemo } from 'react';
import { EFrameType } from '@app/swagger-types';
import { v4 } from 'uuid';

// TODO make all frames scalable
export const FrameContainerSVG: React.FC<{
  scale?: number;
  type?: EFrameType;
  onClick?: (value: EFrameType) => void;
  isOption?: boolean;
  selected?: boolean;
  text?: string | null;
  fontSize?: number;
  color?: string;
  backgroundColor?: string;
  qrBackgroundColor?: string;
  className?: string;
  forWidgetMode?: boolean;
  setFrameRef?: (svg: SVGElement | null) => void;
  setContentRef?: (svg: SVGElement | null) => void;
  containerClassName?: string;
  showDebug?: boolean;
}> = ({
  // TODO handle scale or remove?
  // scale = 1,
  type = EFrameType.NONE,
  onClick,
  text = 'SCAN ME',
  selected = false,
  isOption = false,
  fontSize = BASE_FONT_SIZE,
  color,
  backgroundColor,
  qrBackgroundColor = 'none',
  className,
  // TODO handle mock or remove?
  // children = <MockQRCodeIcon className={clsxm(styles.qrCode, 'pointer-events-none h-full')} />,
  children = null,
  forWidgetMode,
  setFrameRef,
  setContentRef,
  showDebug,
}) => {
  // TODO handle scale or remove?
  // if (scale < 1) {
  //   scale = 1;
  // }
  if (!fontSize) {
    fontSize = BASE_FONT_SIZE;
  }
  if (fontSize < 3) {
    fontSize = 3;
  }

  // TODO: optimize render frames
  const frames = useMemo(() => {
    const BASE_SIZE = 240; // qr code svg width and height
    const commonProps: React.SVGProps<SVGSVGElement> = {
      xmlns: 'http://www.w3.org/2000/svg',
      width: BASE_SIZE,
      height: BASE_SIZE,
      viewBox: `0 0 ${BASE_SIZE} ${BASE_SIZE}`,
      ref: setFrameRef,
      style: {
        width: '100%',
        height: '100%',
      },
    };
    const scaledFontSize = (24 * fontSize) / BASE_FONT_SIZE;
    return {
      [EFrameType.NONE]: (
        <svg {...commonProps}>
          <g ref={setContentRef}>{children}</g>
        </svg>
      ),
      [EFrameType.BORDER_LABEL_BOTTOM]: (() => {
        const BORDER = 4;
        const BORDER_DOUBLE = BORDER * 2;
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2.9;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING + TEXT_RECT_HEIGHT + BORDER_DOUBLE + 1;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <rect
              x={BORDER}
              y={BORDER}
              width={WIDTH - BORDER_DOUBLE}
              height={BASE_SIZE + TEXT_RECT_HEIGHT}
              fill={qrBackgroundColor}
              stroke={backgroundColor}
              strokeWidth={BORDER_DOUBLE}
              rx="8"
              ry="8"
            ></rect>
            <g transform={`translate(${PADDING}, ${PADDING})`} ref={setContentRef}></g>
            <rect
              x="0"
              y={BASE_SIZE + PADDING + BORDER_DOUBLE}
              width={WIDTH}
              height={TEXT_RECT_HEIGHT}
              rx="8"
              ry="8"
              fill={backgroundColor}
            ></rect>
            <text
              x={WIDTH / 2}
              y={BASE_SIZE + PADDING + BORDER_DOUBLE + scaledFontSize * 1.8}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
          </svg>
        );
      })(),
      [EFrameType.BORDER_LABEL_TOP]: (() => {
        const BORDER = 4;
        const BORDER_DOUBLE = BORDER * 2;
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2.9;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING + TEXT_RECT_HEIGHT + BORDER_DOUBLE + 1;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <rect
              x={BORDER}
              y={PADDING + BORDER}
              width={WIDTH - BORDER_DOUBLE}
              height={BASE_SIZE + TEXT_RECT_HEIGHT}
              fill={qrBackgroundColor}
              stroke={backgroundColor}
              strokeWidth={BORDER_DOUBLE}
              rx="8"
              ry="8"
            ></rect>
            <g
              transform={`translate(${PADDING}, ${PADDING + TEXT_RECT_HEIGHT - BORDER_DOUBLE})`}
              ref={setContentRef}
            ></g>
            <rect x="0" y="0" width={WIDTH} height={TEXT_RECT_HEIGHT} rx="8" ry="8" fill={backgroundColor}></rect>
            <text
              x={WIDTH / 2}
              y={BORDER + scaledFontSize * 1.8}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
          </svg>
        );
      })(),
      [EFrameType.ARROW_LABEL_TOP]: (() => {
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING * 2 + TEXT_RECT_HEIGHT + 1;
        const centerX = WIDTH / 2;
        const triangleY = TEXT_RECT_HEIGHT - 1;
        const trianglePath = `M${centerX - PADDING} ${triangleY} L${centerX + PADDING} ${triangleY} L${centerX} ${
          triangleY + PADDING
        } Z`;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <g transform={`translate(${PADDING}, ${PADDING * 1.5 + TEXT_RECT_HEIGHT})`} ref={setContentRef}></g>
            <rect x="0" y="0" width={WIDTH} height={TEXT_RECT_HEIGHT} rx="8" ry="8" fill={backgroundColor}></rect>
            <text
              x={centerX}
              y={scaledFontSize * 1.35}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
            <path d={trianglePath} fill={backgroundColor} />
          </svg>
        );
      })(),
      [EFrameType.ARROW_LABEL_BOTTOM]: (() => {
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING * 2 + TEXT_RECT_HEIGHT + 1;
        const centerX = WIDTH / 2;
        const LABEL_Y = HEIGHT - TEXT_RECT_HEIGHT;
        const triangleY = LABEL_Y + 1;
        const trianglePath = `M${centerX - PADDING} ${triangleY} L${centerX + PADDING} ${triangleY} L${centerX} ${
          triangleY - PADDING
        } Z`;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <g transform={`translate(${PADDING}, ${PADDING * 0.5})`} ref={setContentRef}></g>
            <rect x="0" y={LABEL_Y} width={WIDTH} height={TEXT_RECT_HEIGHT} rx="8" ry="8" fill={backgroundColor}></rect>
            <text
              x={centerX}
              y={LABEL_Y + scaledFontSize * 1.35}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
            <path d={trianglePath} fill={backgroundColor} />
          </svg>
        );
      })(),
      [EFrameType.BORDER_SPACE_LABEL_BOTTOM]: (() => {
        const BORDER = 4;
        const BORDER_DOUBLE = BORDER * 2;
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING * 2.5 + TEXT_RECT_HEIGHT;
        const centerX = WIDTH / 2;
        const LABEL_Y = HEIGHT - TEXT_RECT_HEIGHT;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <rect
              x={BORDER}
              y={BORDER}
              width={WIDTH - BORDER_DOUBLE}
              height={BASE_SIZE + PADDING + BORDER_DOUBLE}
              stroke={backgroundColor}
              strokeWidth={BORDER_DOUBLE}
              rx="8"
              ry="8"
              fill={qrBackgroundColor}
            ></rect>
            <g transform={`translate(${PADDING}, ${PADDING})`} ref={setContentRef}></g>
            <rect x="0" y={LABEL_Y} width={WIDTH} height={TEXT_RECT_HEIGHT} rx="8" ry="8" fill={backgroundColor}></rect>
            <text
              x={centerX}
              y={LABEL_Y + scaledFontSize * 1.35}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
          </svg>
        );
      })(),
      [EFrameType.BORDER_SPACE_LABEL_TOP]: (() => {
        const BORDER = 4;
        const BORDER_DOUBLE = BORDER * 2;
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING * 2.5 + TEXT_RECT_HEIGHT;
        const centerX = WIDTH / 2;
        const LABEL_Y = 0;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <rect
              x={BORDER}
              y={BORDER + TEXT_RECT_HEIGHT + BORDER_DOUBLE}
              width={WIDTH - BORDER_DOUBLE}
              height={BASE_SIZE + PADDING + BORDER_DOUBLE}
              stroke={backgroundColor}
              strokeWidth={BORDER_DOUBLE}
              rx="8"
              ry="8"
              fill={qrBackgroundColor}
            ></rect>
            <g
              transform={`translate(${PADDING}, ${PADDING + TEXT_RECT_HEIGHT + BORDER_DOUBLE})`}
              ref={setContentRef}
            ></g>
            {/* <rect x="0" y={LABEL_Y} width={WIDTH} height={TEXT_RECT_HEIGHT} rx="8" ry="8" fill={backgroundColor}></rect> */}
            <text
              x={centerX}
              y={LABEL_Y + scaledFontSize * 1.6}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
          </svg>
        );
      })(),
      [EFrameType.BORDER_INTERRUPTION]: (() => {
        const BORDER = 4;
        const BORDER_DOUBLE = BORDER * 2;
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING * 2.5 + TEXT_RECT_HEIGHT;
        const LABEL_Y = HEIGHT - TEXT_RECT_HEIGHT;
        const centerX = WIDTH / 2;
        const centerY = (BASE_SIZE + PADDING + BORDER_DOUBLE * 2) * 0.5;
        const gapSize = 0.33;
        const gapX = gapSize * centerX;
        const gapY = gapSize * centerY;
        const safetyShift = 1;
        const maskPath = [
          `M${centerX - gapX} ${centerY - gapY}`,
          `L${centerX - gapX} ${-safetyShift}`,
          `L${centerX + gapX} ${-safetyShift}`,
          `L${centerX + gapX} ${centerY - gapY}`,
          `L${centerX * 2 + safetyShift} ${centerY - gapY}`,
          `L${centerX * 2 + safetyShift} ${centerY + gapY}`,
          `L${centerX + gapX} ${centerY + gapY}`,
          `L${centerX + gapX} ${centerY * 2 + safetyShift}`,
          `L${centerX - gapX} ${centerY * 2 + safetyShift}`,
          `L${centerX - gapX} ${centerY + gapY}`,
          `L${-safetyShift} ${centerY + gapY}`,
          `L${-safetyShift} ${centerY - gapY}`,
          `Z`,
        ].join(' ');
        const maskId = `mask-${v4()}`;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <defs>
              <mask id={maskId}>
                <rect width="100%" height="100%" fill="white" />
                <path d={maskPath} fill="black" />
              </mask>
            </defs>
            <rect
              x={BORDER}
              y={BORDER}
              width={WIDTH - BORDER_DOUBLE}
              height={BASE_SIZE + PADDING + BORDER_DOUBLE}
              stroke={backgroundColor}
              strokeWidth={BORDER_DOUBLE}
              rx="8"
              ry="8"
              fill={'none'}
              mask={`url(#${maskId})`}
            ></rect>
            <g transform={`translate(${PADDING}, ${PADDING})`} ref={setContentRef}></g>
            <text
              x={centerX}
              y={LABEL_Y + scaledFontSize * 1.2}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
            {/* path for mask debug */}
            {/* <path d={maskPath} fill="black" /> */}
          </svg>
        );
      })(),
      [EFrameType.NO_BORDER]: (() => {
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING * 2.5 + TEXT_RECT_HEIGHT;
        const LABEL_Y = HEIGHT - TEXT_RECT_HEIGHT;
        const centerX = WIDTH / 2;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <rect
              x={0}
              y={0}
              width={WIDTH}
              height={BASE_SIZE + PADDING * 2}
              rx="8"
              ry="8"
              fill={qrBackgroundColor}
            ></rect>
            <g transform={`translate(${PADDING}, ${PADDING})`} ref={setContentRef}></g>
            <text
              x={centerX}
              y={LABEL_Y + scaledFontSize * 1.2}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
          </svg>
        );
      })(),
      [EFrameType.BORDER]: (() => {
        const BORDER = 2;
        const BORDER_DOUBLE = BORDER * 2;
        const PADDING = 16;
        const TEXT_RECT_HEIGHT = scaledFontSize * 2;
        const WIDTH = BASE_SIZE + PADDING * 2;
        const HEIGHT = BASE_SIZE + PADDING * 2.5 + TEXT_RECT_HEIGHT;
        const LABEL_Y = HEIGHT - TEXT_RECT_HEIGHT;
        const centerX = WIDTH / 2;
        return (
          <svg {...commonProps} width={WIDTH} height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            <rect
              x={BORDER}
              y={BORDER}
              width={WIDTH - BORDER_DOUBLE}
              height={BASE_SIZE + PADDING + BORDER_DOUBLE}
              stroke={backgroundColor}
              strokeWidth={BORDER_DOUBLE}
              rx="8"
              ry="8"
              fill={qrBackgroundColor}
            ></rect>
            <g transform={`translate(${PADDING}, ${PADDING})`} ref={setContentRef}></g>
            <text
              x={centerX}
              y={LABEL_Y + scaledFontSize}
              textAnchor="middle"
              fontSize={scaledFontSize}
              fontFamily="Arial, sans-serif"
              fill={color}
            >
              {text}
            </text>
          </svg>
        );
      })(),
    };
  }, [backgroundColor, children, text, qrBackgroundColor, color, setFrameRef, setContentRef, fontSize]);

  return (
    <div
      className={clsxm(
        showDebug && 'outline-2 outline outline-red-400',
        onClick && 'hover:cursor-pointer',
        isOption && 'rounded-xl bg-gray-100 p-[10px]',
        isOption && 'border-[1.5px] border-transparent',
        isOption && selected && 'border-accept-main',
        forWidgetMode && 'rounded-lg p-2',
        className
      )}
      onClick={() => {
        onClick && onClick(type);
      }}
      aria-hidden
    >
      {frames[type]}
    </div>
  );
};

const BASE_FONT_SIZE = 12;
