import { clsxm } from '@app/styles/clsxm';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { UITabBranding } from './tab/UITabBranding';
import { UITabButtons } from './tab/UITabButtons';
import { UITabCharts } from './tab/UITabCharts';
import { UITabColors } from './tab/UITabColors';
import { UITabIconButtons } from './tab/UITabIconButtons';
import { UITabInputs } from './tab/UITabInputs';
import { UITabSelects } from './tab/UITabSelects';
import { UITabShadows } from './tab/UITabShadows';
import { UITabSubscription } from './tab/UITabSubscription';
import { UITabTypography } from './tab/UITabTypography';
import { UITabQRCodes } from './tab/UITabQRCodes';

export const UIKitPage: React.FC = () => {
  const [tab, setTab] = useQueryParam('tab', withDefault(StringParam, DEFAULT_TAB));

  return (
    <div className="pb-80">
      <div className="flex justify-center gap-4">
        {TABS_TITLES.map((e, i) => (
          <button key={i} onClick={() => setTab(e, 'replace')} className={clsxm(e === tab && 'underline')}>
            {e}
          </button>
        ))}
      </div>
      {TABS.find((e) => e.title === tab)?.jsx}
    </div>
  );
};

const TABS: {
  title: string;
  jsx: React.ReactNode;
}[] = [
  { title: 'Branding', jsx: <UITabBranding /> },
  { title: 'Colors', jsx: <UITabColors /> },
  { title: 'Typography', jsx: <UITabTypography /> },
  { title: 'Shadows', jsx: <UITabShadows /> },
  { title: 'Buttons', jsx: <UITabButtons /> },
  { title: 'IconButtons', jsx: <UITabIconButtons /> },
  { title: 'Inputs', jsx: <UITabInputs /> },
  { title: 'Selects', jsx: <UITabSelects /> },
  { title: 'Subscription and Billing', jsx: <UITabSubscription /> },
  { title: 'Charts', jsx: <UITabCharts /> },
  { title: 'QR Codes', jsx: <UITabQRCodes /> },
];

const DEFAULT_TAB = TABS[0]?.title || '';
const TABS_TITLES = TABS.map((e) => e.title);
